var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pricing-change-modal-person-page" }, [
    _c("div", { staticClass: "pricing-change-modal-person-page__title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.getDateSpan(_vm.oldSchedule, _vm.translations)) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pricing-change-modal-person-page__tiered-pricing" },
      [
        _c(
          "div",
          {
            staticClass: "switcher",
            on: {
              click: function ($event) {
                return _vm.tieredClick()
              },
            },
          },
          [_c("Checkbox", { attrs: { switcher: "", checked: _vm.tiered } })],
          1
        ),
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.translations.popupPersonThirdPageTieredPriceSwitcher.text
            ) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.tiered
      ? _c(
          "div",
          { staticClass: "pricing-change-modal-person-page__block" },
          [
            _vm.isHaveMinPriceError || _vm.isHaveMaxPriceError
              ? _c("ErrorBanner", {
                  staticClass:
                    "change-pricing-type__error-banner change-pricing-type__error-banner--tiered",
                  attrs: { errors: _vm.errorList },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.prices, function (member, index) {
              return _c(
                "div",
                {
                  key: `age-${index}`,
                  staticClass: "pricing-change-modal-person-page__age-item",
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        index == 0
                          ? "pricing-change-modal-person-page__age-item--checkbox-first"
                          : "pricing-change-modal-person-page__age-item--checkbox",
                      on: {
                        click: function ($event) {
                          return _vm.changeMember(index)
                        },
                      },
                    },
                    [
                      _c("Checkbox", {
                        attrs: {
                          id: `member-${index}`,
                          default: "",
                          name: member.member.name,
                          checked: member.is_active,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-change-modal-person-page__price pricing-change-modal-person-page__price-no-tried",
                    },
                    [
                      index == 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pricing-change-modal-person-page__price--title",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.translations
                                      .popupPersonThirdPageSuggested.text
                                  ) +
                                  "\n          "
                              ),
                              _c("v-question-info", {
                                attrs: {
                                  description:
                                    _vm.translations
                                      .popupPersonThirdPageSuggestedDescription
                                      .text,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-input", {
                        attrs: {
                          error: _vm.$v.prices.$each.$iter[index].price.$error,
                          type: "number",
                          "prepend-text": "EUR",
                          onpaste: "return false;",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.changePriceBlur(index)
                          },
                          input: function ($event) {
                            return _vm.changePrice(index)
                          },
                        },
                        model: {
                          value: _vm.$v.prices.$each.$iter[index].price.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.prices.$each.$iter[index].price,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.prices.$each.$iter[index].price.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-change-modal-person-page__price pricing-change-modal-person-page__price-no-tried pricing-change-modal-person-page__get-paid",
                    },
                    [
                      index == 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pricing-change-modal-person-page__price--title",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.translations.popupPersonThirdPageGetPaid
                                      .text
                                  ) +
                                  "\n          "
                              ),
                              _c("v-question-info", {
                                attrs: {
                                  description:
                                    _vm.translations
                                      .popupPersonThirdPageGetPaidDescription
                                      .text,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-input", {
                        attrs: {
                          value: _vm.showPrice(_vm.prices[index].feePrice),
                          "prepend-text": "EUR",
                          "is-disabled": "",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "pricing-change-modal-person-page__block" },
          [
            _vm.isHaveMinPriceError ||
            _vm.isHaveMaxPriceError ||
            _vm.isHaveAgeError ||
            _vm.isHaveAgeIntervalError
              ? _c("ErrorBanner", {
                  staticClass:
                    "change-pricing-type__error-banner change-pricing-type__error-banner--tiered",
                  attrs: { errors: _vm.errorList },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.prices, function (member, index) {
              return _c(
                "div",
                {
                  key: `age-${index}`,
                  staticClass: "pricing-change-modal-person-page__age-item",
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        index == 0
                          ? "pricing-change-modal-person-page__age-item--checkbox-first-tiered"
                          : "pricing-change-modal-person-page__age-item--checkbox",
                      on: {
                        click: function ($event) {
                          return _vm.changeMember(index)
                        },
                      },
                    },
                    [
                      _c("Checkbox", {
                        attrs: {
                          id: `member-${index}`,
                          default: "",
                          name: member.member.name,
                          checked: member.is_active,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "age__tiered-pricing" },
                    [
                      _vm._l(
                        member.ageTieredGroups,
                        function (ageTieredGroup, ageTieredIndex) {
                          return _c(
                            "div",
                            {
                              key: `ageTieredGroup-${ageTieredIndex}`,
                              staticClass:
                                "pricing-change-modal-person-page__age-item",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pricing-change-modal-person-page__price-min-max",
                                },
                                [
                                  ageTieredIndex == 0 && index == 0
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.translations
                                                .popupPersonThirdPageTieredPriceMinMax
                                                .text
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-change-modal-person-page__price-min-max--input",
                                    },
                                    [
                                      _c("v-input", {
                                        attrs: {
                                          onkeypress:
                                            "return event.charCode >= 48 && event.charCode <= 57",
                                          onpaste: "return false;",
                                          type: "number",
                                          error:
                                            ageTieredGroup.minAgeError ||
                                            _vm.$v.prices.$each.$iter[index]
                                              .ageTieredGroups.$each.$iter[
                                              ageTieredIndex
                                            ].minAge.$error,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.checkMinAgeCount(
                                              index,
                                              ageTieredIndex
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.$v.prices.$each.$iter[index]
                                              .ageTieredGroups.$each.$iter[
                                              ageTieredIndex
                                            ].minAge.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.prices.$each.$iter[index]
                                                .ageTieredGroups.$each.$iter[
                                                ageTieredIndex
                                              ].minAge,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  $v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[\n                    ageTieredIndex\n                  ].minAge.$model\n                ",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("p", [_vm._v("-")]),
                                      _vm._v(" "),
                                      _c("v-input", {
                                        attrs: {
                                          onkeypress:
                                            "return event.charCode >= 48 && event.charCode <= 57",
                                          onpaste: "return false;",
                                          type: "number",
                                          error:
                                            ageTieredGroup.maxAgeError ||
                                            _vm.$v.prices.$each.$iter[index]
                                              .ageTieredGroups.$each.$iter[
                                              ageTieredIndex
                                            ].maxAge.$error,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.checkMinAgeCount(
                                              index,
                                              ageTieredIndex
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.$v.prices.$each.$iter[index]
                                              .ageTieredGroups.$each.$iter[
                                              ageTieredIndex
                                            ].maxAge.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.prices.$each.$iter[index]
                                                .ageTieredGroups.$each.$iter[
                                                ageTieredIndex
                                              ].maxAge,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  $v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[\n                    ageTieredIndex\n                  ].maxAge.$model\n                ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pricing-change-modal-person-page__price",
                                },
                                [
                                  ageTieredIndex == 0 && index == 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-change-modal-person-page__price--title",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.translations
                                                  .popupPersonThirdPageSuggested
                                                  .text
                                              ) +
                                              "\n              "
                                          ),
                                          _c("v-question-info", {
                                            attrs: {
                                              description:
                                                _vm.translations
                                                  .popupPersonThirdPageSuggestedDescription
                                                  .text,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-input", {
                                    attrs: {
                                      error:
                                        _vm.$v.prices.$each.$iter[index]
                                          .ageTieredGroups.$each.$iter[
                                          ageTieredIndex
                                        ].price.$error,
                                      type: "number",
                                      "prepend-text": "EUR",
                                      onpaste: "return false;",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changePriceTiered(
                                          ageTieredGroup
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.changePriceTieredBlur(
                                          ageTieredGroup
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.prices.$each.$iter[index]
                                          .ageTieredGroups.$each.$iter[
                                          ageTieredIndex
                                        ].price.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.prices.$each.$iter[index]
                                            .ageTieredGroups.$each.$iter[
                                            ageTieredIndex
                                          ].price,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                $v.prices.$each.$iter[index].ageTieredGroups.$each.$iter[ageTieredIndex]\n                  .price.$model\n              ",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "pricing-change-modal-person-page__price pricing-change-modal-person-page__get-paid",
                                },
                                [
                                  ageTieredIndex == 0 && index == 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-change-modal-person-page__price--title",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.translations
                                                  .popupPersonThirdPageGetPaid
                                                  .text
                                              ) +
                                              "\n              "
                                          ),
                                          _c("v-question-info", {
                                            attrs: {
                                              description:
                                                _vm.translations
                                                  .popupPersonThirdPageGetPaidDescription
                                                  .text,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-input", {
                                    attrs: {
                                      value: _vm.showPrice(
                                        ageTieredGroup.feePrice
                                      ),
                                      "prepend-text": "EUR",
                                      "is-disabled": "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              ageTieredIndex != 0
                                ? _c("img", {
                                    staticClass:
                                      "pricing-change-modal-person-page__del-cross",
                                    attrs: {
                                      src: require("@/assets/img/svg/del_cross.svg"),
                                      alt: "del_cross",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeField(
                                          ageTieredIndex,
                                          member.ageTieredGroups
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-change-modal-person-page__add-input",
                          on: {
                            click: function ($event) {
                              return _vm.addAgeGroupField(
                                member.ageTieredGroups,
                                { member }
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.translations
                                  .popupPersonThirdPageTieredPriceAdd.text
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              )
            }),
          ],
          2
        ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "change-pricing-type__nav" },
      [
        _c("v-btn", {
          attrs: { outline: "", name: _vm.translations.popupNavBack.text },
          on: { click: _vm.back },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nav__pagination row" },
          _vm._l(_vm.pageCount, function (index) {
            return _c("div", { key: `pageNum${index}` }, [
              _c("div", {
                staticClass: "dot dot_page",
                class: { dot_page_active: index - 1 == _vm.currentPageIndex },
              }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("v-btn", {
          attrs: {
            base: "",
            name: _vm.isLastPage
              ? _vm.translations.popupNavSave.text
              : _vm.translations.popupNavNext.text,
          },
          on: { click: _vm.next },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }