<template>
  <div v-if="attractions.length" class="attractions-list">
    <div class="attractions-list__title">
      {{ translations.attractionsText.text }}
    </div>
    <ul class="attractions-list__list">
      <li v-for="attraction in attractions" :key="attraction.id" class="attractions-list__item">
        <div class="attractions-list__item-photo">
          <LocationIcon class="attractions-list__item-photo-icon" />
        </div>
        <div class="attractions-list__item-content">
          <div class="attractions-list__item-address">
            {{ attraction.address }}
          </div>
          <div class="attractions-list__item-description" v-text="attraction.description" />
          <div class="attractions-list__info">
            <span class="attractions-list__info-item">
              {{ attraction.duration_time }} {{ durationTranslates[attraction.duration_time_unit] }}
            </span>
            <span class="attractions-list__info-item">
              {{ ticketRedemption }}
            </span>
            <span v-if="attraction.is_skip_line" class="attractions-list__info-item">
              {{ translations.textSkipLine.text }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import LocationIcon from '@/components/icons/locationPhoto.vue'

export default {
  name: 'AttractionsList',
  components: { LocationIcon },
  props: {
    attractions: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) }
  },

  computed: {
    durationTranslates () {
      return {
        min: this.translations.timeMin.text,
        hours: this.translations.timeHours.text,
        days: this.translations.timeDays.text
      }
    },
    ticketRedemption () {
      if (this.attractions['ticket_redemption'] === 'Admission is free') {
        return this.translations.AdmissionText1.text;
      } else if (this.attractions['ticket_redemption'] === 'Admission ticket is included') {
        return this.translations.AdmissionText2.text;
      } else {
        return this.translations.AdmissionText3.text;
      }      
    }
  }
}
</script>

<style lang="scss">
.attractions-list {
  margin-bottom: 28px;

  &__title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  &__item {
    display: flex;

    &:not(:last-child) .attractions-list__item-photo {
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 5px;
        bottom: 0;
        background: $dark-blue;
        left: 50%;
        z-index: -1;
      }
    }
  }

  &__item-photo {
    position: relative;
    display: flex;
    margin-right: 26px;
  }

  &__item-photo-icon {
    filter: drop-shadow(0px 4px 7px #00000026);
  }

  &__item-content {
    max-width: 670px;
    padding-top: 12px;
    padding-bottom: 24px;
  }

  &__item-address {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }

  &__item-description {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    margin: 5px 0 10px;
    white-space: pre-wrap;
  }

  &__info {
    font-size: 14px;
    font-weight: 500;
    color: $light-gray;
    display: flex;
    align-items: center;
  }

  &__info-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    &:not(:last-child)::after {
      content: '';
      display: block;
      background-color: $light-gray;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: 0 5px;
      flex-shrink: 0;
    }
  }
}
</style>
