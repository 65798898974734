<template>
  <div class="product-card">
    <div :to="`/product/${slug}/product-content`" class="product-card__picture">
      <img v-if="picture != ''" :src="picture" alt="product" />
      <img v-else src="@/assets/img/no_image.jpg" alt="product" />
    </div>
    <div class="product-card__body">
      <div :to="`/product/${slug}/product-content`" class="main-info">
        <div class="title">
          {{ title }}
        </div>
        <span
          v-if="description != '' && description != null"
          class="description pre-wrap"
          >{{ description }}</span
        >
        <div v-else class="description">
          {{ productTranslate.productNoDescription.text }}
        </div>
        <div class="info">
          <div v-if="status == 'draft'" class="info__status">
            {{ productTranslate.productStatus1.text }}
          </div>
          <div v-if="status == 'in_review'" class="info__status">
            {{ productTranslate.productStatus2.text }}
          </div>
          <div v-if="status == 'in_stock'" class="info__status">
            {{ productTranslate.productStatus3.text }}
          </div>
          <div class="dot" />
          <div class="info__code">
            {{ `${productTranslate.productCode.text} ${productCode}` }}
          </div>
          <div v-if="status !== 'in_stock'" class="dot" />
          <nuxt-link
            v-if="status !== 'in_stock'"
            :to="localePath(`/product/${slug}/preview`)"
            tag="a"
          >
            <div class="info__preview">{{ productTranslate.textPreview.text }}</div>
          </nuxt-link>
          <div v-if="status == 'in_stock'" class="dot" />
          <a
            v-if="status == 'in_stock'"
            class="info__view-vidodo"
            :href="`https://test.vidodoguide.com/catalog/${slug}`"
            target="_blank"
            >{{ productTranslate.productView.text }} Vidodo</a
          >
        </div>
      </div>
      <div class="second-info">
        <div class="price">
          <div
            v-if="priceTotal != 'no price' && priceTotal > 0 && priceSale > 0"
            class="price_old"
          >
            {{ formatCurrency(priceOld, lang) }}
          </div>
          <div v-else class="price_from">
            {{ productTranslate.productPriceFrom.text }}
          </div>
          <div v-if="priceOld > 0 && priceTotal > 0" class="price_sale">
            {{ formatCurrency(priceSale, lang) }}
          </div>
          <div v-if="priceTotal > 0" class="price_total">
            {{ formatCurrency(priceTotal, lang) }}
          </div>
          <div v-else class="price_total">
            {{ productTranslate.productNoPrice.text }}
          </div>
        </div>
        <div class="edit">
          <div v-if="!isSetup" tag="a" @click="getStepLink()">
            <Btn outline :name="productTranslate.productEditBtn.text" />
          </div>
          <nuxt-link v-else :to="localePath(`/product/${slug}/product-content`)" tag="a">
            <Btn outline :name="productTranslate.productEditBtn.text" />
          </nuxt-link>
        </div>
        <div class="buttons">
          <div v-if="status == 'draft'" class="buttons__delete" @click="deleteProduct">
            <img src="@/assets/img/svg/del-basket.svg" alt="del-basket" />
          </div>
          <div v-if="status == 'draft'" class="buttons__review" @click="sendToReview">
            <Btn outline :name="productTranslate.productReviewBtn.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/controls/Btn.vue";
import { formatCurrency } from "@/utils/utils";
export default {
  components: {
    Btn,
  },
  props: [
    "title",
    "isSetup",
    "description",
    "status",
    "productCode",
    "priceTotal",
    "priceOld",
    "priceSale",
    "picture",
    "slug",
    "productTranslate",
    "tourID",
  ],
  data: () => ({
    lang: "",
  }),
  async fetch() {
    this.lang = this.$cookies.get("i18n_redirected");
  },
  methods: {
    formatCurrency,
    deleteProduct() {
      this.$emit("deleteProduct");
    },
    sendToReview() {
      this.$emit("sendToReview");
    },
    async getStepLink() {
      const editStep = await this.$axios.$get(
        `/api/v1/get-tour-creation-steps/${this.tourID}`
      );
      let stepLink = "basic/product-setup";
      if (!editStep.product_setup) {
        stepLink = "basic/product-setup";
      } else if (!editStep.categories_themes) {
        stepLink = "basic/categories-and-themes";
      } else if (!editStep.photos) {
        stepLink = "basic/photos";
      } else if (!editStep.meeting_pickup) {
        stepLink = "product-content/meeting-and-pickup";
      } else if (!editStep.activity_unique) {
        stepLink = "product-content/makes-unique";
      } else if (!editStep.activity_details) {
        stepLink = "product-content/activities-details";
      } else if (!editStep.inclusion_exclusions) {
        stepLink = "product-content/inclusions-and-exclusions";
      } else if (!editStep.guides_languages) {
        stepLink = "product-content/guides-and-languages";
      } else if (!editStep.information_from_you) {
        stepLink = "product-content/information-edit";
      } else if (!editStep.covid) {
        stepLink = "product-content/covid";
      } else if (!editStep.pricing_type) {
        stepLink = "schedules-and-pricing/pricing-type";
      } else if (!editStep.schedules) {
        stepLink = "schedules-and-pricing/schedules";
      } else if (!editStep.booking_process) {
        stepLink = "booking-and-tickets/booking-process";
      } else if (!editStep.cancellation_policy) {
        stepLink = "booking-and-tickets/cancellation-policy";
      } else if (!editStep.information_from_travelers) {
        stepLink = "booking-and-tickets/information-edit";
      } else if (!editStep.tickets_details) {
        stepLink = "booking-and-tickets/ticket-details";
      } else if (!editStep.tickets_preview) {
        stepLink = "booking-and-tickets/ticket-preview";
      } else if (!editStep.special_offer) {
        stepLink = "finish/special-offers";
      } else {
        stepLink = "finish/submit-for-review";
      }
      const routeData = this.$router.resolve({
        name: this.localePath(`/product/${this.slug}/build/${stepLink}`),
      });
      window.open(routeData.location.name);
    },
  },
};
</script>

<style lang="scss">
.product-card {
  width: 100%;
  height: 180px;
  display: flex;
  &__picture {
    overflow: hidden;
    border-radius: 10px;
    z-index: 1;
    width: 292px;
    height: 180px;
    min-width: 292px;
    min-height: 180px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__body {
    margin-left: -15px;
    padding: 15px 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: $white;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    z-index: 2;
    .main-info {
      display: flex;
      flex-direction: column;
      width: 580px;
      .title {
        overflow: hidden;
        max-height: 25px;
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      }
      .description {
        flex: auto;
        display: block;
        overflow: hidden;
        font-size: 16px;
        line-height: 130%;
        //max-height: 80px;
        //margin-bottom: 33px;
        height: 102px;
      }
      .info {
        bottom: 20px;
        display: flex;
        align-items: center;
        padding: 0px;
        &__status {
          font-weight: 600;
          margin-right: 16px;
          font-size: 16px;
          line-height: 20px;
        }
        .dot {
          margin-right: 16px;
          width: 6px;
          height: 6px;
        }
        &__code {
          margin-right: 16px;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
        &__view-vidodo {
          text-decoration: none;
          color: $blue;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
        &__preview {
          color: $blue;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          cursor: pointer;
        }
      }
    }
    .second-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .price {
        margin-top: 21px;
        margin-bottom: 20px;
        width: 195px;
        position: relative;
        &_total {
          font-size: 22px;
          line-height: 26px;
          text-align: right;
          &_sale {
            color: $orange;
          }
        }
        &_old {
          text-decoration: line-through;
          position: absolute;
          bottom: 25px;
          right: 2px;
          font-size: 16px;
          line-height: 19px;
          color: $light-gray;
          &_no {
            position: absolute;
            bottom: 25px;
            right: 2px;
            font-size: 12px;
            line-height: 14px;
            color: $light-gray;
          }
        }
        &_from {
          position: absolute;
          bottom: 25px;
          right: 2px;
          font-size: 16px;
          line-height: 19px;
          color: $light-gray;
        }
        &_sale {
          position: absolute;
          top: 27px;
          right: 0;
          font-size: 12px;
          line-height: 14px;
          color: $orange;
        }
      }
      .edit {
        margin-bottom: 10px;
      }
      .buttons {
        display: flex;
        align-items: center;
        float: right;
        &__delete {
          z-index: 10;
          cursor: pointer;
          margin-right: 10px;
          width: 40px;
          height: 40px;
          background: $white;
          opacity: 1;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $gray;
          transition: all 0.3s ease-out;
          img {
            filter: brightness(0);
            width: 20px;
            height: 20px;
          }
          &:hover {
            background: $orange;
            img {
              filter: brightness(1);
            }
          }
        }
        &__review {
          z-index: 10;
          .btn {
            padding: 0 10px;
            width: max-content;
          }
        }
      }
    }
  }
}
</style>
