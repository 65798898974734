<template>
  <div v-click-outside="clearSearch" class="categories-form-tags">
    <div class="categories-form-tags__subcategory-select" @click="clearSubcategory" v-if="false">
      <span v-if="selectedSubcategory">{{ selectedSubcategory.title }}</span>
      <span v-else class="categories-form-tags__subcategory-placeholder">{{
        translations.selectOneCategory?.text
      }}</span>

      <img src="@/assets/img/svg/drop-down-arrow.svg" alt="drop" />
    </div>
    <div class="categories-form-tags__selected-wrapper">
      <div class="categories-form-tags__selected" :class="{ 'categories-form-tags__selected--error': error }">
        <input
          v-if="!maxReaches"        
          v-model="searchString"
          type="text"
          class="categories-form-tags__search"
          :placeholder="translations.tagsPlaceholder.text"
        />
        <span v-for="tag in localTags" :key="tag.id" class="categories-form-tags__selected-item">
          {{ tag.title }}
          <button class="categories-form-tags__selected-delete" @click="deleteTag(tag.id)">
            <BaseIcon class="categories-form-tags__selected-delete-icon" icon-name="Close" />
          </button>
        </span>
      </div>
    </div>
    <!-- <div v-if="selectedSubcategory || searchString.length > 0" class="categories-form-tags__list">
      <div v-for="subcategoryTag in filteredTags" :key="subcategoryTag.id" class="categories-form-tags__subcategory">
        <VCheckbox
          v-model="localTags"
          class="categories-form-tags__tag"
          :value="subcategoryTag"
          :label="subcategoryTag.title"
        />
      </div>
    </div>
    <div v-else class="categories-form-tags__list">
      <div
        v-for="subcategory in subcategories"
        :key="subcategory.id"
        class="categories-form-tags__subcategory"
        @click="selectSubcategory(subcategory)"
      >
        {{ subcategory.title }} <img class="arrow-right" src="@/assets/img/svg/arrow-right.svg" alt="arrow-right" />
      </div>
    </div> -->
    <div class="categories-form-tags__list">
      <div v-for="subcategory in subcategories" :key="subcategory.id" class="categories-form-tags__subcategory">
        <VCheckbox
          v-model="localTags"
          class="categories-form-tags__tag"
          :value="subcategory"
          :label="subcategory.title"
          :disabled="toggleCheckbox(subcategory)"
          :checkOnRight="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon.vue';
import VCheckbox from '@/components/controls/VCheckbox.vue';

export default {
  name: 'CategoriesFormTags',
  components: { BaseIcon, VCheckbox },

  model: {
    prop: 'tags',
    event: 'update:tags',
  },

  props: {
    subcategories: { type: Array, default: () => [] },
    selectedSubcategories: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    error: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) },
    maxCount: { type: Number, default: 1},
  },

  data() {
    return {
      searchString: '',
      selectedSubcategory: null,
    };
  },

  computed: {
    localTags: {
      get() {
        return this.tags;
      },
      set(tags) {
        this.$emit('update:tags', tags);
      },
    },

    filteredTags() {
      if (this.searchString) {
        const allTags = this.subcategories.reduce((tags, subcategory) => {
          return [...tags, ...subcategory.tags];
        }, []);
        return allTags.filter((tag) => tag.title.toLowerCase().includes(this.searchString.toLowerCase()));
      }

      if (this.selectedSubcategory) {
        return this.selectedSubcategory.tags;
      }

      return [];
    },

    maxReaches() {
      return this.selectedSubcategories.length >= this.maxCount;
    }
  },

  methods: {
    toggleCheckbox(subcategory) {
      if (this.selectedSubcategories.includes(subcategory)) return false;
      if (this.maxReaches) return true;
      return false;
    },

    clearSearch() {
      this.searchString = '';
    },

    selectSubcategory(subcategory) {
      this.selectedSubcategory = subcategory;
    },

    clearSubcategory() {
      this.selectSubcategory(null);
    },

    deleteTag(id) {
      this.localTags = this.localTags.filter((tag) => tag.id !== id);
    },
  },
};
</script>

<style lang="scss">
.categories-form-tags {
  width: 450px;

  &__selected-wrapper {
    //background: #f0f0f0;
    //padding: 6px 16px;
    padding: 0;
  }

  &__search {
    border: none;
    font-weight: 400;
  }

  &__selected {
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    min-height: 60px;
    background: #fff;
    border-radius: 4px;
    padding: 5px;

    &--error {
      background-color: #ffe2e3;
      border-color: $red;
    }
  }

  &__placeholder {
    color: #969ba9;
  }

  &__selected-item {
    display: flex;
    align-items: center;
    padding: 3px 13px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    background: #f9f9f9;
    font-size: 14px;
  }

  &__selected-delete {
    border: none;
    background: none;
    display: block;
    width: 18px;
    height: 18px;
    margin-left: 5px;
  }

  &__selected-delete-icon {
    width: 100%;
    height: 100%;
  }

  &__list {
    border: 1px solid #d9d9d9;
    border-top: unset;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    height: 380px;
    overflow: auto;
  }

  &__subcategory {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px;
    border-bottom: 1px solid #f0f0f0;
    transition: all 0.3s;

    .v-checkbox__mark {
      border-radius: 50%;
    }

    &:hover {
      background-color: #f4f4f4;
    }
  }

  &__tag {
    width: 100%;
    display: contents;
  }

  &__subcategory-select {
    border: 1px solid #d9d9d9;
    border-radius: 4px 4px 0 0;
    padding: 16px 21px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__subcategory-placeholder {
    color: #999999;
  }
}
</style>
