var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDisplay
    ? _c(
        "div",
        { staticClass: "preview__section", attrs: { id: "drop-off" } },
        [
          _c("div", { staticClass: "preview__title" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.translations.dropOffPoint.text) + "\n  "
            ),
          ]),
          _vm._v(" "),
          _c("VExpansionList", { attrs: { items: _vm.getDropOffs, blue: "" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }