<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-select-language">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close" />
      </div>
      <div class="pop-up-select-language__content">
        <div class="title">
          {{ headerTranslate.popupTitle.text }}
        </div>
        <div class="subtitle">
          {{ headerTranslate.popupSubtitle.text }}
        </div>
        <div class="language">
          <div
            v-for="(language, index) in languages"
            :key="`language-${index}`"
            class="language__item"
            @click="activeLang(index)"
          >
            <RadioButton
              :id="`language-${index}`"
              default
              :name="language.name"
              radio-name="select-language"
              :checked="language.is_checked"
            />
          </div>
        </div>
        <div class="nav">
          <div class="nav__cancel" @click="closeWindow">
            <Btn outline :name="headerTranslate.cancelBtn.text" />
          </div>
          <div class="nav__save" @click="saveLang">
            <Btn base :name="headerTranslate.saveBtn.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Btn from "@/components/controls/Btn.vue";
import RadioButton from "@/components/controls/RadioButton.vue";

export default {
  components: {
    Btn,
    RadioButton,
  },
  props: ["isActive", "headerTranslate"],
  data: () => ({
    languages: [
      { name: "English", is_checked: true, abbrivations: "en" },
      { name: "Español", is_checked: false, abbrivations: "es" },
    ],
    lang: "",
  }),
  async fetch() {
    const lang = this.$cookies.get("i18n_redirected");
    let haveActiveLang = false;
    for (const i in this.languages) {
      if (this.languages[i].abbrivations == lang) {
        this.languages[i].is_checked = true;
        haveActiveLang = true;
      }
    }
    if (haveActiveLang == false) {
      this.languages[0].is_checked = true;
    }
  },
  methods: {
    ...mapActions(["reloadTranslations"]),
    closeWindow() {
      const active = this.isActive;
      this.$emit("close", active);
    },
    activeLang(index) {
      for (const i in this.languages) {
        this.languages[i].is_checked = false;
      }
      this.languages[index].is_checked = true;
      this.lang = this.languages[index].abbrivations;
    },
    async saveLang() {
      const active = this.isActive;
      this.$emit("close", active);
      this.$i18n.setLocale(this.lang);
      await this.$axios.$patch("/api/v1/users/me/", { language: this.lang });
      this.reloadTranslations();
    },
  },
};
</script>

<style lang="scss">
.pop-up-select-language {
  position: relative;
  background: $white;
  padding: 35px 30px 25px 30px;
  width: 600px;
  &__content {
    .title {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    }
    .subtitle {
      margin-bottom: 45px;
    }
    .language {
      margin-bottom: 40px;
      &__item {
        margin-bottom: 10px;
      }
    }
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ececec;
      padding-top: 23px;
      .btn {
        &_outline {
          margin-right: 15px;
          border: 2px solid $primary;
          border-radius: 4px;
          min-width: 102px;
          height: 48px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
        }
        &_base {
          border: 2px solid $primary;
          border-radius: 4px;
          min-width: 102px;
          height: 48px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
        }
      }
    }
  }
}
</style>
