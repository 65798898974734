export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en-EN.js"},{"code":"es","file":"es-ES.js"},{"code":"de","file":"de-DE.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "~/langs/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true,"onlyOnRoot":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","file":"en-EN.js"},{"code":"es","file":"es-ES.js"},{"code":"de","file":"de-DE.js"}],
  localeCodes: ["en","es","de"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "E",
  6: "N",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "s",
  21: "/",
  22: "e",
  23: "n",
  24: "-",
  25: "E",
  26: "N",
  27: ".",
  28: "j",
  29: "s",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "s",
  35: "-",
  36: "E",
  37: "S",
  38: ".",
  39: "j",
  40: "s",
  41: "\"",
  42: ":",
  43: "\"",
  44: ".",
  45: ".",
  46: "/",
  47: "l",
  48: "a",
  49: "n",
  50: "g",
  51: "s",
  52: "/",
  53: "e",
  54: "s",
  55: "-",
  56: "E",
  57: "S",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: ",",
  63: "\"",
  64: "d",
  65: "e",
  66: "-",
  67: "D",
  68: "E",
  69: ".",
  70: "j",
  71: "s",
  72: "\"",
  73: ":",
  74: "\"",
  75: ".",
  76: ".",
  77: "/",
  78: "l",
  79: "a",
  80: "n",
  81: "g",
  82: "s",
  83: "/",
  84: "d",
  85: "e",
  86: "-",
  87: "D",
  88: "E",
  89: ".",
  90: "j",
  91: "s",
  92: "\"",
  93: "}",
}

export const localeMessages = {
  'en-EN.js': () => import('../../langs/en-EN.js' /* webpackChunkName: "lang-en-EN.js" */),
  'es-ES.js': () => import('../../langs/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
  'de-DE.js': () => import('../../langs/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
}
