<template>
  <div class="breadcrumbs">
    <img src="@/assets/img/svg/breadcrumbs-arrow.svg" alt="small-arrow">
    <nuxt-link v-if="path" class="breadcrumbs__text" :to="localePath(path)"> {{ title }} </nuxt-link>
    <button v-else class="breadcrumbs__text" @click="handleClick">{{ title }}</button>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: ''},
    path: { type: String, default: ''}
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.breadcrumbs {
  margin-bottom: 16px;
  display: flex;
  &__text {
    margin-left: 6px;
    font-size: 12px;
    line-height: 16px;
    color: $light-gray-two;
    background: none;
    border: none;
    transition: all 0.3s ease-in;
    &:hover {
      color: $primary;
    }
  }
  .arrow {
    margin: 0 5px;
    font-size: 10px;
    line-height: 16px;
    color: $light-gray-two;
  }
}
</style>
