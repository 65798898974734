var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-card" }, [
    _c(
      "div",
      {
        staticClass: "product-card__picture",
        attrs: { to: `/product/${_vm.slug}/product-content` },
      },
      [
        _vm.picture != ""
          ? _c("img", { attrs: { src: _vm.picture, alt: "product" } })
          : _c("img", {
              attrs: {
                src: require("@/assets/img/no_image.jpg"),
                alt: "product",
              },
            }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "product-card__body" }, [
      _c(
        "div",
        {
          staticClass: "main-info",
          attrs: { to: `/product/${_vm.slug}/product-content` },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
          ]),
          _vm._v(" "),
          _vm.description != "" && _vm.description != null
            ? _c("span", { staticClass: "description pre-wrap" }, [
                _vm._v(_vm._s(_vm.description)),
              ])
            : _c("div", { staticClass: "description" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.productTranslate.productNoDescription.text) +
                    "\n      "
                ),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "info" },
            [
              _vm.status == "draft"
                ? _c("div", { staticClass: "info__status" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.productTranslate.productStatus1.text) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "in_review"
                ? _c("div", { staticClass: "info__status" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.productTranslate.productStatus2.text) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "in_stock"
                ? _c("div", { staticClass: "info__status" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.productTranslate.productStatus3.text) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "dot" }),
              _vm._v(" "),
              _c("div", { staticClass: "info__code" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      `${_vm.productTranslate.productCode.text} ${_vm.productCode}`
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.status !== "in_stock"
                ? _c("div", { staticClass: "dot" })
                : _vm._e(),
              _vm._v(" "),
              _vm.status !== "in_stock"
                ? _c(
                    "nuxt-link",
                    {
                      attrs: {
                        to: _vm.localePath(`/product/${_vm.slug}/preview`),
                        tag: "a",
                      },
                    },
                    [
                      _c("div", { staticClass: "info__preview" }, [
                        _vm._v(_vm._s(_vm.productTranslate.textPreview.text)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "in_stock"
                ? _c("div", { staticClass: "dot" })
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "in_stock"
                ? _c(
                    "a",
                    {
                      staticClass: "info__view-vidodo",
                      attrs: {
                        href: `https://test.vidodoguide.com/catalog/${_vm.slug}`,
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.productTranslate.productView.text) +
                          " Vidodo"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "second-info" }, [
        _c("div", { staticClass: "price" }, [
          _vm.priceTotal != "no price" &&
          _vm.priceTotal > 0 &&
          _vm.priceSale > 0
            ? _c("div", { staticClass: "price_old" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.formatCurrency(_vm.priceOld, _vm.lang)) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "price_from" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.productTranslate.productPriceFrom.text) +
                    "\n        "
                ),
              ]),
          _vm._v(" "),
          _vm.priceOld > 0 && _vm.priceTotal > 0
            ? _c("div", { staticClass: "price_sale" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.formatCurrency(_vm.priceSale, _vm.lang)) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.priceTotal > 0
            ? _c("div", { staticClass: "price_total" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.formatCurrency(_vm.priceTotal, _vm.lang)) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "price_total" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.productTranslate.productNoPrice.text) +
                    "\n        "
                ),
              ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "edit" },
          [
            !_vm.isSetup
              ? _c(
                  "div",
                  {
                    attrs: { tag: "a" },
                    on: {
                      click: function ($event) {
                        return _vm.getStepLink()
                      },
                    },
                  },
                  [
                    _c("Btn", {
                      attrs: {
                        outline: "",
                        name: _vm.productTranslate.productEditBtn.text,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "nuxt-link",
                  {
                    attrs: {
                      to: _vm.localePath(
                        `/product/${_vm.slug}/product-content`
                      ),
                      tag: "a",
                    },
                  },
                  [
                    _c("Btn", {
                      attrs: {
                        outline: "",
                        name: _vm.productTranslate.productEditBtn.text,
                      },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _vm.status == "draft"
            ? _c(
                "div",
                {
                  staticClass: "buttons__delete",
                  on: { click: _vm.deleteProduct },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/svg/del-basket.svg"),
                      alt: "del-basket",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.status == "draft"
            ? _c(
                "div",
                {
                  staticClass: "buttons__review",
                  on: { click: _vm.sendToReview },
                },
                [
                  _c("Btn", {
                    attrs: {
                      outline: "",
                      name: _vm.productTranslate.productReviewBtn.text,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }