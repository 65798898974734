<template>
  <div class="your-expertise-form">
    <VRadioGroup
      v-model="isProfessional"
      class="your-expertise-form__field"
      :label="translations.titleProfessional?.text"
      name="isProfessional"
    >
      <template #default="{ on, attrs }">
        <VRadioButton :value="true" v-bind="attrs" v-on="on"> {{ translations.optionYes?.text }} </VRadioButton>
        <VRadioButton :value="false" v-bind="attrs" v-on="on"> {{ translations.optionNo?.text }} </VRadioButton>
      </template>
    </VRadioGroup>

    <VRadioGroup
      v-if="isProfessional"
      v-model="hasLicense"
      name="hasLicense"
      class="your-expertise-form__field"
      :label="translations.titleHaveLicense?.text"
      :sub-label="translations.descrHaveLicense?.text"
    >
      <template #default="{ on, attrs }">
        <VRadioButton :value="true" v-bind="attrs" v-on="on"> {{ translations.optionYes?.text }} </VRadioButton>
        <VRadioButton :value="false" v-bind="attrs" v-on="on"> {{ translations.optionNo?.text }} </VRadioButton>
      </template>
    </VRadioGroup>

    <VInput
      v-model="$v.profession.$model"
      :error="$v.profession.$error"
      class="your-expertise-form__field"
      :label="translations.titleYourProfession?.text"
      :optional="isProfessional ? $store.state.commonTranslate.optional.text : ''"
      :sub-label="translations.descriptionYourProfession?.text"
      :placeholder="translations.answerYourProfession?.text"
      :error-message="translations.errorProfession?.text"
    />

    <VBtn class="your-expertise-form__button" :loading="loading" base :name="saveBtnText" @click="save" />
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

import VInput from '@/components/controls/VInput.vue'
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VBtn from '@/components/controls/VBtn.vue'

export default {
  name: 'YourExpertiseForm',
  components: { VInput, VBtn, VRadioGroup, VRadioButton },
  props: {
    yourExpertise: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    saveBtnText: { type: String, default: '' }
  },

  data() {
    return {
      profession: '',
      isProfessional: false,
      hasLicense: true
    }
  },

  validations() {
    return {
      profession: { required: requiredIf(() => !this.isProfessional) }
    }
  },

  mounted() {
    if (this.yourExpertise) {
      const { profession, isProfessional, hasLicense } = this.yourExpertise
      this.profession = profession
      this.isProfessional = isProfessional
      this.hasLicense = hasLicense
    }
  },

  methods: {
    save() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('save', {
        profession: this.profession,
        isProfessional: this.isProfessional,
        hasLicense: this.isProfessional ? this.hasLicense : false
      })
    }
  }
}
</script>

<style lang="scss">
.your-expertise-form {
  max-width: 720px;

  &__field {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__button {
    margin-top: 72px;
  }
}
</style>
