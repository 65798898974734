<template>
  <div class="ticket-paper-preview" :style="cssVars">
    <div class="ticket-paper-preview__content">
      <div class="voucher">
        <div class="ticket">
          <div class="column">
            <div class="column__content">
              <img class="logo" src="@/assets/img/header/logo.svg" alt="logo" />
              <div class="tour-name">
                {{ cutVaucher('tourName', tour.name) }}
              </div>
              <div v-if="tour.get_tour_schedule.length != 0" class="info-list">
                <div class="ticket-paper-preview__info">
                  <img src="@/assets/img/svg/tour-date-gray.svg" alt="tour-date" />
                  <p class="ticket-paper-preview__info-text">{{ formattedDate }}</p>
                </div>
                <div class="ticket-paper-preview__info">
                  <img src="@/assets/img/svg/tour-time-gray.svg" alt="tour-start-time" />
                  {{ tourStarting }}
                </div>
                <div class="ticket-paper-preview__info">
                  <img src="@/assets/img/svg/tour-duration-gray.svg" alt="tour-duration" />
                  <p class="ticket-paper-preview__info-text">{{ translations.duration.text }}: {{ durationTitle }}</p>
                </div>
                <div v-if="language" class="ticket-paper-preview__info">
                  <img src="@/assets/img/svg/tour-language-gray.svg" alt="" />
                  <p class="ticket-paper-preview__info-text">{{ language }}</p>
                </div>
                <div class="ticket-paper-preview__info">
                  <img src="@/assets/img/svg/tour-amount-gray.svg" alt="tour-members-amount" />
                  <p class="ticket-paper-preview__info-text">1 {{ memberAgeCategory }}</p>
                </div>
              </div>
              <div class="lead-name">{{ translations.mobileBuyer.text }}</div>
            </div>
          </div>
          <div class="column">
            <div class="column__content">
              <img v-if="tour.paper_logo" class="supplier-logo" :src="tour.paper_logo" alt="supp-logo" />
              <div class="activity-operator">
                <div class="activity-operator__title">{{ translations.activitysOperator.text }}</div>
                <div class="activity-operator__description">
                  {{ tour.seller.company_name }}
                </div>
              </div>
              <div class="column__content--qrcode-box">
                <div v-if="tour.ticket_redemption == 'direct'" class="qrcode">
                  <img src="@/assets/img/svg/qr.svg" alt="tour-qrcode" />
                </div>
                <div v-else class="important-info">
                  <div class="important-info__text important-info__text_important">
                    {{ translations.isNotEntryVoucher.text }}
                  </div>
                  <div v-if="tour.ticket_redemption === 'delivery'" class="important-info__text">
                    {{ parseTranslate(translations.presentVoucherDelivery.text, tour.ticket_redemption_address || '') }}
                  </div>
                  <div v-else class="important-info__text">
                    {{ translations.presentVoucher.text }}
                  </div>
                </div>
                <div v-if="false" class="column__content--reference-code">
                  {{ tour.reference_code }}
                </div>
              </div>
              <div class="booking-code">
                <div class="booking-code__title">{{ translations.paperCode.text }}</div>
                <div class="booking-code__description">VG123456789</div>
              </div>
            </div>
          </div>
        </div>
        <div class="notification">
          {{ translations.operatorWillCall.text }}
        </div>
        <div class="main-content">
          <div class="main-content__column">
            <div class="info">
              <div v-if="tour.pick_ups.length > 0" class="info__item">
                <div class="title">{{ translations.pickupTime.text }}</div>
                <div class="description">
                  {{
                    parseTranslate(
                      betweenDateStart.length > 1 ? translations.operatorPickUpTime.text : translations.operatorPickUpTimeSingle.text,
                      ...placeholderBetweenDateStart,
                    )
                  }}
                </div>
              </div>
              <div v-if="tour.pick_ups.length > 0" class="info__item">
                <div class="title">{{ translations.pickupPoint.text }}</div>
                <div class="description">
                  {{ tour.pick_ups[0].address }}
                </div>
              </div>
              <!-- Meeting point -->
              <div v-if="tour.meeting_point" class="info__item">
                <div class="title">{{ translations.meetingPoint.text }}</div>
                <div class="description">
                  {{ tour.meeting_point.address }}
                </div>
              </div>

              <!-- Meeting point details -->
              <div v-if="tour.meeting_point" class="info__item">
                <div class="title">{{ translations.meetingPointDetails.text }}</div>
                <div class="description">
                  {{ tour.meeting_point.description }}
                </div>
              </div>

              <!-- Final destination -->
              <div v-if="tour.meeting_point && !tour.meeting_point.is_end_point" class="info__item">
                <div class="title">{{ translations.finalDestination.text }}</div>
                <div class="description">
                  {{ tour.end_point.address }}
                </div>
              </div>

              <!-- Final destination details -->
              <div class="info__item">
                <div class="title">{{ translations.mobilePage5.text }}</div>
                <div v-if="tour.meeting_point == null || tour.meeting_point.is_end_point" class="description">
                  {{ translations.droppedOffSamePlace.text }}
                </div>
                <div v-else class="description">
                  {{ tour.end_point.description }}
                </div>
              </div>

              <div v-if="tour.get_tour_includes.length > 0 || tour.get_tour_excludes.length > 0" class="info__item">
                <div class="title">{{ translations.includedExcluded.text }}</div>
                <div v-if="tour.get_tour_includes.length > 0" class="description">
                  {{ translations.whatsIncluded.text }}
                </div>
                <div v-if="tour.get_tour_includes.length > 0" class="list">
                  <div v-for="(include, index) in tour.get_tour_includes" :key="`include-${index}`" class="list__item">
                    <div class="dot" />
                    {{ include.description }}
                  </div>
                </div>
                <div v-if="tour.get_tour_excludes.length > 0" class="description">
                  {{ translations.whatsExcluded.text }}
                </div>
                <div v-if="tour.get_tour_excludes.length > 0" class="list">
                  <div v-for="(exclude, index) in tour.get_tour_excludes" :key="`exclude-${index}`" class="list__item">
                    <div class="dot" />
                    {{ exclude.description }}
                  </div>
                </div>
              </div>

              <!-- Before you go -->
              <div v-if="tour.tour_leaving && tour.tour_leaving.length > 0" class="info__item">
                <div class="title">{{ translations.mobilePage6.text }}</div>

                <div class="description">
                  <div class="description__item">{{ tour.tour_leaving }}</div>
                </div>
              </div>

              <div v-if="tour.covid_security.length > 0 || tour.covid_requirements.length > 0" class="info__item">
                <div class="title">{{ translations.mobilePage2.text }}</div>
                <div v-if="tour.covid_security.length > 0" class="description">
                  {{ translations.securityMeasuresAreTaken.text }}
                </div>
                <div v-if="tour.covid_security.length > 0" class="list">
                  <div v-for="(security, index) in tour.covid_security" :key="`security-${index}`" class="list__item">
                    <div class="dot" />
                    {{ security.text }}
                  </div>
                </div>
                <div v-if="tour.covid_requirements.length > 0" class="description">
                  {{ translations.requirementsForParticipants.text }}
                </div>
                <div v-if="tour.covid_requirements.length > 0" class="list">
                  <div
                    v-for="(requirements, index) in tour.covid_requirements"
                    :key="`requirements-${index}`"
                    class="list__item"
                  >
                    <div class="dot" />
                    {{ requirements.text }}
                  </div>
                </div>
              </div>

              <!-- Drop off -->
              <div v-if="getDropOffs.length > 0" class="info__item">
                <div class="title">{{ translations.titleDropOff.text }}:</div>
                <div class="list">
                  <div
                    v-for="(dropOff, dropOffIndex) in getDropOffs"
                    :key="`drop-off${dropOffIndex}`"
                    class="list__item"
                  >
                    <div class="dot" />
                    <div>
                      <div class="list__item--address">{{ dropOff.address }}</div>
                      <div class="list__item--details">{{ dropOff.details }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-content__column">
            <div class="info">
              <div class="info__item">
                <div class="title">{{ translations.paperImportant.text }}</div>

                <div class="description">
                  <div class="description__item">
                    {{ getDownloadVoucher[0] }}

                    <a href="https://www.vidodoguide.com/app" target="_blank">vidodoguide.com/app</a>

                    {{ getDownloadVoucher[1] }}
                  </div>

                  <div class="description__item">
                    {{ translations.emailInfo.text }}
                  </div>

                  <div class="description__item">{{ tour.tour_important_info }}</div>
                </div>
              </div>

              <!-- Activity operator information -->
              <div class="info__item">
                <div class="title">{{ translations.activityPperator.text }}</div>

                <div class="description__item">
                  <div class="title">{{ translations.activityProvidedBy.text }} {{ tour.seller.company_name }}.</div>

                  <div class="title">{{ translations.contactActivityOperator.text }}</div>
                </div>

                <div class="title">
                  <div class="description__item">
                    {{ translations.paperPhone.text }}

                    <a :href="`tel:+${userInfo.contact_phone}`">{{ userInfo.contact_phone }}</a>
                  </div>

                  <div class="description__item">
                    {{ translations.paperEmail.text }}

                    <a :href="`mailTo:${userInfo.contact_email}`">{{ userInfo.contact_email }}</a>
                  </div>
                </div>
              </div>

              <div class="info__item">
                <div class="title">{{ translations.bookingManagment.text }}</div>
                <div class="description">
                  <div class="description__item">
                    {{ refundData }}
                  </div>
                  <div class="description__item">
                    {{ translations.helpPage.text }} (
                    <a href="https://www.vidodoguide.com/help" target="_blank"> vidodoguide.com/help </a>
                    ) -
                    {{ `“${translations.bookingManagment.text}”` }}
                  </div>
                  <div class="description__item description__item_booking">
                    {{ translations.paperCode.text }} <b>VD123456789</b>
                  </div>
                  <div class="description__item description__item_pin">
                    {{ translations.paperPin.text }} <b>!FD=jsHdb</b>
                  </div>
                </div>
              </div>
              <div class="info__item">
                <div class="title">{{ translations.cancellationPolicy.text }}</div>
                <div class="list">
                  <div v-for="(policy, index) in cancellationPolicy" :key="`policy-${index}`" class="list__item">
                    <div class="dot" />
                    {{ policy }}
                  </div>
                </div>
              </div>

              <div class="info__item">
                <div class="title">{{ translations.contactUs.text }}</div>
                <div class="title">
                  <div class="description__item">
                    {{ translations.forGeneralInquiries.text }}
                    <a>+34 928 299 630</a> - {{ translations.duringBusinessHours.text.slice(0, -1) }}, 
                    {{ parseTranslate(translations.textContact.text, 'help@vidodoguide.com', '+34 676 717 130') }}
                  </div>
                </div>
              </div>
              <div class="gift">
                <div class="info">
                  <div class="info__name">{{ translations.yourGift.text }} <b>{{ translations.southTour.text }}</b></div>
                  <div class="info__description">
                    {{ translations.receiveGiftCode.text }}
                  </div>
                  <div class="info__description">
                    {{ translations.downloadAppQr.text }}
                  </div>
                </div>
                <div class="qrcode">
                  <img src="@/assets/img/svg/qr-site.svg" alt="qr-site" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ticket-paper-preview__map">
          <div class="ticket-paper-preview__map--marker" />

          <div v-if="tour.meeting_point" class="ticket-paper-preview__map-address-card">
            <div class="ticket-paper-preview__map-address-card--title">{{ translations.paperMeetingPoint.text }}</div>
            <div class="ticket-paper-preview__map-address-card--address">
              {{ tour.meeting_point.address }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cutVaucher } from './utils';
import { format } from '@/utils/date';
import { parseTranslate } from '@/utils/translations';
import { getTourStarting, normalizeRange } from '~/utils/vaucherUtils';

import { STANDARD_CANCELLATION, NO_REFUND } from '@/constants/cancellationTypes';

import { formatTime } from '@/utils/utils';
import { getLocaleByLanguage } from '~/utils/timeHelper';
import imgTicket3 from '@/assets/img/ticket3.png';

export default {
  name: 'TicketPaperPreview',

  props: {
    lang: { type: String, required: true },
    translations: { type: Object, default: () => ({}) },
    tour: { type: Object, required: true },
  },

  computed: {
    ...mapState(['userInfo']),

    cssVars() {
      return {
        '--ticket-paper-preview-map-url': `url(https://backend.vidodoguide.com/api/v1/location-image?c=${
          this.tour.meeting_point ? this.tour.meeting_point.lat : 0
        },${
          this.tour.meeting_point ? this.tour.meeting_point.lon : 0
        }&w=700&h=364&z=17&f=0&t=0&ppi=500&vt=1&nodot=1&nocp=1)`,
      };
    },
    companyName() {
      return this.$store.state.userInfo.company_trading_name;
    },

    tourStarting() {
      return getTourStarting.call(this, this.tour, this.translations, this.lang);
    },

    schedule() {
      return this.tour.get_tour_schedule[0];
    },

    formattedDate() {
      return format(this.schedule.start_date, 'EEEE, d MMMM yyyy', undefined, this.lang);
    },

    time() {
      return this.schedule?.price_schedules?.[0]?.available_time[0] || '';
    },

    language() {
      const count = this.tour.languages?.length ?? 0;
      if (count < 1) return undefined;
      if (this.lang === 'es') return 'Inglés';
      return 'English';
    },

    isFlexDuration() {
      return this.tour.duration_type === 'flexible_time';
    },

    memberAgeCategory() {
      const members = this.schedule?.price_schedules?.[0]?.members;

      if (members.length) {
        return members[0]?.member?.age_category.name;
      }

      return this.translations.mobileAmount.text;
    },

    durationTitle() {
      return normalizeRange(this.tour.duration_time, this.tour.duration_time_unit, this.lang);
    },

    durationUnitTranslate() {
      const durations = {
        h: this.translations.DHours.text,
        min: this.translations.DMinutes.text,
        d: this.translations.DDays.text,
      };
      return durations[this.tour.duration_time_unit] || this.tour.duration_time_unit;
    },

    ticketRedemption() {
      let ticketRedemption = '';
      if (this.tour.ticket_redemption === 'direct') {
        ticketRedemption = '';
      } else if (this.tour.ticket_redemption === 'exchange') {
        ticketRedemption = this.translations.ticketRedemptionExchange.text;
      } else if (this.tour.ticket_redemption === 'delivery') {
        ticketRedemption = this.translations.presentVoucher.text;
      }

      return ticketRedemption;
    },
    getDropOffs() {
      return this.tour.drop_offs === undefined ? [] : this.tour.drop_offs;
    },
    placeholderBetweenDateStart() {
      return this.betweenDateStart.map(() => 'XX:XX');
    },
    betweenDateStart() {
      if (this.tour.pick_ups.length === 0) return [''];
      
      // Получаем время начала тура
      const availableTime = this.tour.get_tour_schedule[0].price_schedules[0].available_time;
      if (availableTime.length === 0) return [''];

      const part = availableTime[0][0];
      const strDate = new Date().toJSON().substring(0, 11) + part;
      const date = new Date(strDate);

      // Получаем длительность пикапа из tour
      const { pick_up_duration_time, pick_up_duration_time_unit } = this.tour;

      let pickUpDurationInMinutes = 0;

      if (pick_up_duration_time.includes('-')) {
        // Если это диапазон
        const [startDuration, endDuration] = pick_up_duration_time.split('-').map(Number);
        pickUpDurationInMinutes = pick_up_duration_time_unit === 'h'
          ? [startDuration * 60, endDuration * 60]
          : [startDuration, endDuration];
      } else {
        // Если это одно значение
        pickUpDurationInMinutes = pick_up_duration_time_unit === 'h'
          ? parseInt(pick_up_duration_time) * 60
          : parseInt(pick_up_duration_time);
      }

      // Вычисляем время пикапа
      if (Array.isArray(pickUpDurationInMinutes)) {
        const startPickupTime = new Date(date.getTime() - pickUpDurationInMinutes[1] * 60 * 1000);
        const endPickupTime = new Date(date.getTime() - pickUpDurationInMinutes[0] * 60 * 1000);

        const locale = getLocaleByLanguage(this.lang);
        return [
          startPickupTime.toLocaleString(locale, { timeStyle: 'short' }),
          endPickupTime.toLocaleString(locale, { timeStyle: 'short' })
        ];
      } else {
        const pickupTime = new Date(date.getTime() - pickUpDurationInMinutes * 60 * 1000);
        const locale = getLocaleByLanguage(this.lang);
        return [pickupTime.toLocaleString(locale, { timeStyle: 'short' })];
      }
    },

    cancellationPolicyStandart() {
      return [
        this.translations.cancellationPolicyStandart1.text,
        this.translations.cancellationPolicyStandart2.text,
        this.translations.cancellationPolicyStandart3.text,
        this.translations.cancellationPolicyStandart4.text,
      ];
    },

    cancellationPolicyStrict() {
      return [
        this.translations.cancellationPolicyStrict1.text,
        this.translations.cancellationPolicyStrict2.text,
        this.translations.cancellationPolicyStrict3.text,
        this.translations.cancellationPolicyStrict4.text,
        this.translations.cancellationPolicyStandart4.text,
      ];
    },

    cancellationPolicy() {
      const type = this.tour.cancellation_type;
      let cancllationPolicyList = [];

      if (type === STANDARD_CANCELLATION) {
        cancllationPolicyList = this.cancellationPolicyStandart;
      } else if (type !== NO_REFUND) {
        cancllationPolicyList = this.cancellationPolicyStrict;
      }

      const isWeather = this.tour.cancellation_reasons.find((reason) => reason.id === 1);
      const isMinTravalers = this.tour.cancellation_reasons.find((reason) => reason.id === 2);

      if (isWeather || type === NO_REFUND) {
        cancllationPolicyList.push(this.translations.cancellationPolicyWeather.text);
      }

      if (isMinTravalers || type === NO_REFUND) {
        cancllationPolicyList.push(this.translations.cancellationPolicyTravelersNumber.text);
      }

      return cancllationPolicyList;
    },

    getDownloadVoucher() {
      return this.translations.downloadVoucher.text.split(
        '<a href="https://www.vidodoguide.com/app" target="_blank">vidodoguide.com/app</a>'
      );
    },

    meetingPoint() {
      if (!this.tour.meeting_point) {
        return null;
      }

      return {
        lat: Number(this.tour.meeting_point.lat),
        lon: Number(this.tour.meeting_point.lon),
        address: this.tourMainAddress,
      };
    },

    refundData() {
      const dayMilliseconds = 24 * 60 * 60 * 1000;

      const currentDate = new Date(this.schedule.start_date);
      currentDate.setTime(currentDate.getTime() - dayMilliseconds);
      if (this.tour.cancellation_type === 'no_refund') return 'This ticket is non-refundable';
      else if (this.tour.cancellation_type === 'strict')
        currentDate.setTime(currentDate.getTime() - dayMilliseconds * 2);


      const locale = getLocaleByLanguage(this.lang);
      const time = currentDate.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
      const date = new Date(currentDate).toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
      const text = parseTranslate(this.translations.freeCancelUntil.text, time, date);
      return text;
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      const elTicket = document.querySelector('.ticket')
      const elName = elTicket.querySelector('.tour-name');
      const lineHeight = Number(window.getComputedStyle(elName).lineHeight?.replace('px', ''));
      const lineCount = elName.clientHeight / lineHeight;
      if (lineCount === 3) {
        elTicket.style.backgroundImage = `url("${imgTicket3}")`;
        elTicket.style.height = '356px';
      }
    }      
  },
  methods: {
    cutVaucher,
    formatTime,
    parseTranslate,
  }
};
</script>

<style lang="scss">
.ticket-paper-preview {
  overflow: hidden;
  width: 825px;
  max-width: 100%;
  padding: 10px;
  box-shadow: 2px 3px 10px 0px #00000040;

  &__map {
    margin: 30px auto;
    border-radius: 8px;
    background-image: var(--ticket-paper-preview-map-url);
    background-position-x: center;
    background-position-y: center;
    width: 700px;
    height: 364px;
    position: relative;
    &--marker {
      background: url('~/assets/img/marker.png') no-repeat center center;
      width: 27.182px;
      height: 32.727px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }

  &__map-address-card {
    border-radius: 8px;
    border: 2px solid $dark-blue;
    background: #fff;
    width: 288px;
    padding: 14px 13px 15px 16px;
    font-size: 16px;
    font-style: normal;
    position: absolute;
    bottom: 18px;
    left: 18px;

    &--title {
      font-weight: 700;
      color: $dark-blue;
    }

    &--address {
      font-weight: 500;
      color: #8f93a1;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
  }

  &__info-text {
    font-size: 18px;
    font-weight: 600;
  }

  &__content {
    width: 805px;
    background: $white;
    padding: 43px 80px 9px 60px;
    .voucher {
      .ticket {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        max-width: 730px;
        height: 330px;
        width: 729px;
        background: url('~assets/img/ticket2.png') no-repeat center center;
        background-size: contain;
        margin: 0 auto 20px auto;
        margin-left: -16px;
        .column {
          padding: 29px 30px;
          &:first-child {
            width: 490px;
            padding-left: 35px;
          }
          &:last-child {
            width: 220px;
            padding: 10px 10px 19px 13px;
            margin-top: 6px;
          }
          &__content {
            position: relative;
            &--qrcode-box {
              display: flex;
            }
            &--reference-code {
              position: absolute;
              top: 0;
              right: -5px;
              bottom: 0;
              writing-mode: vertical-rl;
              text-align: center;
              word-break: break-all;
              font-size: 13px;
              transform: rotate(180deg);
              margin-top: 10px;
            }
            .logo {
              width: 141px;
              height: 42px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .tour-name {
              font-weight: 500;
              font-size: 24px;
              max-width: 425px;
              width: 395px;
              margin-bottom: 10px;
              margin-top: 10px;
              min-height: 52px;
              line-height: 26px;
            }
            .info-list {
              display: flex;
              flex-direction: column;
              gap: 4px;
              margin-bottom: 15px;
              &__item {
                position: relative;
                padding-left: 30px;
                font-weight: 700;
                &::before {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 24px;
                  height: 24px;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  left: 0;
                  top: 50%;
                  -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
                }
                &_date {
                  &::before {
                    background: url('~assets/img/svg/tour-date-gray.svg') no-repeat center center;
                  }
                }
                &_time {
                  &::before {
                    background: url('~assets/img/svg/tour-time-gray.svg') no-repeat center center;
                  }
                }
                &_language {
                  &::before {
                    background: url('~assets/img/svg/tour-language-gray.svg') no-repeat center center;
                  }
                }
                &_amount {
                  &::before {
                    background: url('~assets/img/svg/tour-amount-gray.svg') no-repeat center center;
                  }
                }
                font-size: 18px;
                &:not(:last-child) {
                  margin-bottom: 2px;
                }
              }
            }
            .lead-name {
              font-size: 22px;
              font-weight: 700;
            }
            .supplier-logo {
              margin: 0 auto;
              margin-bottom: 5px;
              width: 91px;
              height: 42px;
              object-fit: contain;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .activity-operator {
              font-size: 13px;
              &__title {
                text-align: center;
                font-weight: bold;
                font-size: 13px;
                line-height: 100%;
              }
              &__description {
                text-align: center;
                font-size: 13px;
                line-height: 100%;
                word-break: break-word;
              }
            }
            .qrcode {
              width: 128px;
              height: 128px;
              margin: 0 auto;
              margin-top: 7px;
              margin-bottom: 7px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .important-info {
              position: relative;
              width: 128px;
              height: 128px;
              padding-left: 26px;
              margin-top: 15px;
              margin-bottom: 20px;
              width: 210px;
              height: fit-content;
              margin-right: 15px;
              &__text {
                margin: 0 auto;
                font-size: 12px;
                line-height: 120%;
                font-weight: bold;
                &_important {
                  font-size: 13px;
                  line-height: 120%;
                  color: #ff3a44;
                }
              }
              &::before {
                position: absolute;
                width: 20px;
                height: 20px;
                content: '';
                left: 0;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                background: url('~assets/img/info-icon.png') no-repeat center center;
                background-size: cover;
              }
            }
            .booking-code {
              font-size: 13px;
              &__title {
                text-align: center;
                font-weight: bold;
                font-size: 13px;
                line-height: 100%;
              }
              &__description {
                text-align: center;
                font-size: 13px;
                line-height: 100%;
              }
            }
          }
        }
      }
      .notification {
        font-size: 13px;
        width: 700px;
        padding: 11px 14px;
        color: #a48756;
        background-color: #fff4e1;
        border-radius: 10px;
      }
      .main-content {
        width: 700px;
        position: relative;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        align-items: flex-start;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-top: 24px;
        gap: 27px;
        &__column {
          width: 343px;
          .info {
            &__item {
              a {
                color: blue;
                font-size: 13px;
                line-height: 120%;
              }
              b {
                font-size: 13px;
                line-height: 120%;
              }
              margin-bottom: 8px;
              .title {
                font-size: 13px;
                line-height: 120%;
                font-weight: bold;
                margin-bottom: 2px;
                word-break: break-word;
              }
              .description {
                font-size: 13px;
                line-height: 120%;
                &_important {
                  color: #ff3a44;
                }
                &__item {
                  font-size: 13px;
                  line-height: 120%;
                  margin-bottom: 5px;
                }
              }
              .list {
                list-style: square outside;
                margin-bottom: 10px;
                &__item {
                  word-break: break-word;
                  word-wrap: anywhere;
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  font-size: 13px;
                  line-height: 140%;

                  &--address {
                    font-size: 13px;
                    margin-top: -4px;
                  }

                  &--details {
                    font-size: 13px;
                  }
                  .dot {
                    width: 4px;
                    height: 4px;
                    margin-right: 8px;
                    margin-top: 6px;
                  }
                }
              }
            }
            .gift {
              display: flex;
              position: relative;
              background-color: rgba(238, 238, 238, 0.6);
              padding: 10px 6px 10px 14px;
              border-radius: 8px;
              margin-top: 15px;
              .info {
                width: 215px;
                &__name {
                  b {
                    font-size: 16px;
                    color: #000;
                  }
                  position: relative;
                  padding-left: 30px;
                  font-size: 16px;
                  font-weight: 700;
                  color: #565e72;
                  margin-bottom: 8px;
                  line-height: 120%;
                  &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 24px;
                    height: 25px;
                    background: url('~assets/img/svg/gift-small.svg') no-repeat center center;
                    background-size: cover;
                    left: 0;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                  }
                }
                &__description {
                  font-size: 14px;
                  line-height: 120%;
                }
                &__download {
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 120%;
                }
              }
              .qrcode {
                width: 105px;
                height: 105px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
